.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.custom-loader {
  width: 140px;
  height: 140px;
  background:
    radial-gradient(farthest-side,#201D41 90%,#0000) center/32px 32px,
    radial-gradient(farthest-side,#0F0F14   90%,#0000) bottom/24px 24px;
  background-repeat: no-repeat;
  animation: s7 1s infinite linear;
  position: relative;
}
.custom-loader::before {    
  content:"";
  position: absolute;
  width:16px;
  height:16px;
  inset:auto 0 32px;
  margin: auto;
  background:#2F2F31;
  border-radius: 50%;
  transform-origin: 50% calc(100% + 20px);
  animation:inherit;
  animation-duration: 0.5s;
}


@keyframes s7 { 
  100%{transform: rotate(1turn)}
}