.bottom-sheet-wrapper {
    -webkit-transition: opacity 500ms cubic-bezier(0.03, 0.72, 0.36, 0.99);
    -moz-transition: opacity 500ms cubic-bezier(0.03, 0.72, 0.36, 0.99);
    -ms-transition: opacity 500ms cubic-bezier(0.03, 0.72, 0.36, 0.99);
    -o-transition: opacity 500ms cubic-bezier(0.03, 0.72, 0.36, 0.99);
    transition: opacity 500ms cubic-bezier(0.03, 0.72, 0.36, 0.99);
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 998;
    width: 100%;
    height: 100%;
    opacity: 0;
  }
  .bottom-sheet-wrapper .bottom-sheet-block-layer {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color:  rgba(0, 23, 20, 0.8);
  }
  .bottom-sheet-wrapper .bottom-sheet {
    max-height: 95vh !important;
    overflow-y: auto;
    -webkit-transition: transform 500ms cubic-bezier(0.03, 0.72, 0.36, 0.99);
    -moz-transition: transform 500ms cubic-bezier(0.03, 0.72, 0.36, 0.99);
    -ms-transition: transform 500ms cubic-bezier(0.03, 0.72, 0.36, 0.99);
    -o-transition: transform 500ms cubic-bezier(0.03, 0.72, 0.36, 0.99);
    transition: transform 500ms cubic-bezier(0.03, 0.72, 0.36, 0.99);
    -webkit-transform: translate3d(0px, 100%, 0);
    -moz-transform: translate3d(0px, 100%, 0);
    -ms-transform: translate3d(0px, 100%, 0);
    -o-transform: translate3d(0px, 100%, 0);
    transform: translate3d(0px, 100%, 0);
    position: absolute;
    bottom: 0px;
    border-radius: 7px 7px 0 0;
    box-shadow: 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 6px 10px 0 rgba(0, 0, 0, 0.14),
      0 3px 5px -1px rgba(0, 0, 0, 0.2);
    z-index: 999;
    width: 100%;
  
    background-color: #fff;
  }
  .bottom-sheet-wrapper.hide {
    display: none;
  }
  .bottom-sheet-wrapper .bottom-sheet-item {
    display: block;
    width: 100%;
    background-color: #fff;
    text-align: center;
    padding: 20px 0;
    border: none;
    border-top: 1px solid #ededed;
    font-size: 16px;
  }
  .bottom-sheet-wrapper .bottom-sheet-item.cancel {
    color: #d0021b;
    visibility: hidden;
  }
  .bottom-sheet-wrapper .bottom-sheet-item.cancel:after {
    color: #d0021b;
    content: "Close";
    visibility: visible;
    display: block;
    border-top: 1px solid #ededed;
    padding-top: 15px;
  }
  .bottom-sheet-wrapper .toast {
    bottom: 110%;
  }
  .bottom-sheet-wrapper.enter {
    opacity: 1;
  }
  .bottom-sheet-wrapper.enter .bottom-sheet {
    -webkit-transform: translate3d(0px, 0px, 0);
    -moz-transform: translate3d(0px, 0px, 0);
    -ms-transform: translate3d(0px, 0px, 0);
    -o-transform: translate3d(0px, 0px, 0);
    transform: translate3d(0px, 0px, 0);
  }
  
