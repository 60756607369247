/* theme */
:root {
    --white: #fff;
    --lightGray: #f9f9f9;
    --mediumGray: #eaeaea;
    --darkGray: #6b7179;
    --lightBlue: #e6f2ff;
    --lightRed: #fff0f0;
    --darkBlue: #006eed;
    --darkRed: #d70000;
    --black: #000;
  }
  
  html,
  body {
    box-sizing: border-box;
  }
  
  * {
    padding: 0;
    margin: 0;
    font-family: 'Rubic', sans-serif;
  }
  
  .font-rubic {
    font-family: 'Rubic', sans-serif;
  }
  
  .font-lexend-deca {
    font-family: 'Lexend Deca', sans-serif;
  }
  
  /* text style */
  .text-10 {
    font-size: 10px;
    font-weight: 400;
    line-height: 15px;
  }
  .text-12 {
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
  }
  .text-14 {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }
  .text-16 {
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
  }

  .text-16b{
    font-size: 16px;
    font-weight: 800; 
  }
  .text-20 {
    font-size: 20px;
    font-weight: 400;
    line-height: 20px;
  }
  .text-20b {
    font-size: 20px;
    font-weight: 600;
    line-height: 20px;
  }
  
  /* padding-horizontal*/
  .ph-2 {
    padding-left: 2px;
    padding-right: 2px;
  }
  .ph-4 {
    padding-left: 4px;
    padding-right: 4px;
  }
  .ph-8 {
    padding-left: 8px;
    padding-right: 8px;
  }
  .ph-16 {
    padding-left: 16px;
    padding-right: 16px;
  }
  .ph-24 {
    padding-left: 24px;
    padding-right: 24px;
  }
  .ph-32 {
    padding-left: 32px;
    padding-right: 32px;
  }
  /* padding-vertical */
  .pv-2 {
    padding-top: 2px;
    padding-right: 2px;
  }
  .pv-4 {
    padding-top: 4px;
    padding-bottom: 4px;
  }
  .pv-8 {
    padding-top: 8px;
    padding-bottom: 8px;
  }
  .pv-16 {
    padding-top: 16px;
    padding-bottom: 16px;
  }
  .pv-24 {
    padding-top: 24px;
    padding-bottom: 24px;
  }
  .pv-32 {
    padding-top: 32px;
    padding-bottom: 32px;
  }
  .pv-64 {
    padding-top: 64px;
    padding-bottom: 64px;
  }
  /* padding-top */
  .pt-2 {
    padding-top: 2px;
  }
  .pt-4 {
    padding-top: 4px;
  }
  .pt-8 {
    padding-top: 8px;
  }
  .pt-16 {
    padding-top: 16px;
  }
  .pt-24 {
    padding-top: 24px;
  }
  .pt-32 {
    padding-top: 32px;
  }
  .pt-64 {
    padding-top: 64px;
  }
  .pt-0 {
    padding-top: 0;
  }
  /* padding-bottom */
  .pb-2 {
    padding-bottom: 2px;
  }
  .pb-4 {
    padding-bottom: 4px;
  }
  .pb-8 {
    padding-bottom: 8px;
  }
  .pb-16 {
    padding-bottom: 16px;
  }
  .pb-24 {
    padding-bottom: 24px;
  }
  .pb-32 {
    padding-bottom: 32px;
  }
  .pb-64 {
    padding-bottom: 64px;
  }
  .pb-0 {
    padding-bottom: 0;
  }
  /* padding-left */
  .pl-2 {
    padding-left: 2px;
  }
  .pl-4 {
    padding-left: 4px;
  }
  .pl-8 {
    padding-left: 8px;
  }
  .pl-16 {
    padding-left: 16px;
  }
  .pl-24 {
    padding-left: 24px;
  }
  .pl-32 {
    padding-left: 32px;
  }
  .pl-64 {
    padding-left: 64px;
  }
  .pl-0 {
    padding-left: 0;
  }
  /* padding-right */
  .pr-2 {
    padding-right: 2px;
  }
  .pr-4 {
    padding-right: 4px;
  }
  .pr-8 {
    padding-right: 8px;
  }
  .pr-16 {
    padding-right: 16px;
  }
  .pr-24 {
    padding-right: 24px;
  }
  .pr-32 {
    padding-right: 32px;
  }
  .pr-64 {
    padding-right: 64px;
  }
  .pr-0 {
    padding-right: 0;
  }
  
  /* margin-horizonal */
  .mh-2 {
    margin-left: 2px;
    margin-right: 2px;
  }
  .mh-4 {
    margin-left: 4px;
    margin-right: 4px;
  }
  .mh-8 {
    margin-left: 8px;
    margin-right: 8px;
  }
  .mh-16 {
    margin-left: 16px;
    padding-right: 16px;
  }
  .mh-24 {
    margin-left: 24px;
    margin-right: 24px;
  }
  .mh-32 {
    margin-left: 32px;
    margin-right: 32px;
  }
  /* margin-vertical */
  .mv-2 {
    margin-top: 2px;
    margin-right: 2px;
  }
  .mv-4 {
    margin-top: 4px;
    margin-bottom: 4px;
  }
  .mv-8 {
    margin-top: 8px;
    margin-bottom: 8px;
  }
  .mv-10{
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .mv-16 {
    margin-top: 16px;
    margin-bottom: 16px;
  }
  .mv-24 {
    margin-top: 24px;
    margin-bottom: 24px;
  }
  .mv-32 {
    margin-top: 32px;
    margin-bottom: 32px;
  }
  /* margin-top */
  .mt-2 {
    margin-top: 2px;
  }
  .mt-4 {
    margin-top: 4px;
  }
  .mt-8 {
    margin-top: 8px;
  }
  .mt-10 {
    margin-top:10px;
  }
  .mt-16 {
    margin-top: 16px;
  }
  .mt-24 {
    margin-top: 24px;
  }
  .mt-32 {
    margin-top: 32px;
  }
  .mt-64 {
    margin-top: 64px;
  }
  .mt-0 {
    margin-top: 0;
  }
  /*margin-bottom*/
  .mb-2 {
    margin-bottom: 2px;
  }
  .mb-4 {
    margin-bottom: 4px;
  }
  .mb-8 {
    margin-bottom: 8px;
  }
  .mb-10 {
    margin-bottom: 10px;
  }
  .mb-16 {
    margin-bottom: 16px;
  }
  .mb-24 {
    margin-bottom: 24px;
  }
  .mb-32 {
    margin-bottom: 32px;
  }
  .mb-64 {
    margin-bottom: 64px;
  }
  .mb-0 {
    margin-bottom: 0;
  }
  /*margin-left*/
  .ml-2 {
    margin-left: 2px;
  }
  .ml-4 {
    margin-left: 4px;
  }
  .ml-8 {
    margin-left: 8px;
  }
  .ml-16 {
    margin-left: 16px;
  }
  .ml-24 {
    margin-left: 24px;
  }
  .ml-32 {
    margin-left: 32px;
  }
  .ml-64 {
    margin-left: 64px;
  }
  .ml-0 {
    margin-left: 0;
  }
  /* margin-right */
  .mr-2 {
    margin-right: 2px;
  }
  .mr-4 {
    margin-right: 4px;
  }
  .mr-8 {
    margin-right: 8px;
  }
  .mr-16 {
    margin-right: 16px;
  }
  .mr-24 {
    margin-right: 24px;
  }
  .mr-32 {
    margin-right: 32px;
  }
  .mr-64 {
    margin-right: 64px;
  }
  .mr-0 {
    margin-right: 0;
  }
  
  /* BorderRadius */
  .br-8 {
    border-radius: 8px;
  }
  .br-16 {
    border-radius: 16px;
  }
  .br-24 {
    border-radius: 24px;
  }
  .br-32 {
    border-radius: 32px;
  }
  
  /*Flexbox*/
  /* flexrow- */
  .fr-ac {
    display: flex;
    align-items: center;
  }
  .fr-jc {
    display: flex;
    justify-content: center;
  }
  .fr-js {
    display: flex;
    justify-content: flex-start;
  }
  .fr-je {
    display: flex;
    justify-content: flex-end;
  }
  .fr-jsb {
    display: flex;
    justify-content: space-between;
  }
  .fr-jsa {
    display: flex;
    justify-content: space-around;
  }
  .fr-jse {
    display: flex;
    justify-content: space-evenly;
  }
  /* flexcolumn- */
  .fc-ac {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .fc-jc {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .fc-js {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  .fc-je {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
  .fc-jsb {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .fc-jsa {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
  .fc-jse {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }
  
  /*component styles*/
  .app-container {
    box-shadow: -2px 0px 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
  }
  
  .inherit-size {
    height: 100%;
    width: 100%;
  }
  
  .inherit-width {
    width: 100%;
  }
  
  .inherit-height {
    height: 100%;
  }
  
  
  /* datepicker css override */
  .datepicker .datepicker-navbar {
    padding: 0 0.5em 0.5em 0.5em;
    overflow: hidden;
    display: flex;
    height: 60px;
  }
  .datepicker-navbar {
    flex-direction: row-reverse;
  }
  .datepicker.default .datepicker-navbar-btn {
    width: 100%;
  }
  .datepicker .datepicker-scroll>li {
    font-size: 14px;
    font-family: "Lexend Deca", sans-serif;
  }
  .datepicker.default .datepicker-wheel {
    border-top: none;
    border-bottom: 1px solid #263999;
  }

  .google-button{
    border:none !important;
    height:55px !important;
    padding: 11px 14px !important;
    box-shadow:0 0 15.4px 0.6px rgb(0 0 0 / 9%) ;
    background: white;
    font-size: 16px !important;
    font-weight: 800 !important;

  }
  
  .datepicker.default {
    border: 1px solid #bfbfbf !important;
    box-shadow: 0 0 15.4px 0.6px rgb(0 0 0 / 9%);
    border-radius: 14px;
    background-color: #ffffff !important;
}

@media screen and (max-width: 736px) {
  .video_control video {
    width: 300px;
    margin: 0 auto;
  }
}